<template>
  <div style="margin: 0 20px 0 20px">
    <el-row style="height: 50px; display: flex; align-items: center">
      <el-col :span="4"
        ><div
          style="font-weight: bold; font-size: 20px; height: 100%; color: black"
        >
          农机作业数据分析平台
        </div></el-col
      >
      <el-col :span="4" :offset="15">
        <div style="height: 50px; display: flex; align-items: center">
          <span></span>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin-top: 20px" :gutter="20">
      <el-col :span="20">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-card>
              <div>
                <div style="font-size: 15px">在线农机总数</div>
              </div>
              <div
                style="
                  font-weight: bold;
                  font-size: 16px;
                  color: blue;
                  margin-top: 15px;
                "
              >
                {{ cardData.zxnjzs }} 台
              </div>
              <div style="font-size: 10px; margin-top: 10px">
                <span v-if="false" style="font-size: 10px; color: green">
                  ↑ {{ 50 }}%</span
                >
                <span v-else style="font-size: 10px; color: red">
                  ↓ {{ cardData.zxnjzsjzr }}%</span
                >
                <span style="font-size: 10px; color: gray"> 较昨日</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card>
              <div>
                <div style="font-size: 15px">今日作业面积</div>
              </div>
              <div
                style="
                  font-weight: bold;
                  font-size: 16px;
                  color: blue;
                  margin-top: 15px;
                "
              >
                {{ cardData.jrzymj }} 亩
              </div>
              <div style="font-size: 10px; margin-top: 10px">
                <span v-if="false" style="font-size: 10px; color: green">
                  ↑ {{ 50 }}%</span
                >
                <span v-else style="font-size: 10px; color: red">
                  ↓ {{ cardData.jrzymjjzr }}%</span
                >
                <span style="font-size: 10px; color: gray"> 较昨日</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card>
              <div>
                <div style="font-size: 15px">作业完成率</div>
              </div>
              <div
                style="
                  font-weight: bold;
                  font-size: 16px;
                  color: blue;
                  margin-top: 15px;
                "
              >
                {{ cardData.zywcl }} %
              </div>
              <div style="font-size: 10px; margin-top: 10px">
                <span v-if="false" style="font-size: 10px; color: green">
                  ↑ {{ 50 }}%</span
                >
                <span v-else style="font-size: 10px; color: red">
                  ↓ {{ cardData.zywcljzr }}%</span
                >
                <span style="font-size: 10px; color: gray"> 较昨日</span>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card>
              <div>
                <div style="font-size: 15px">农机利用率</div>
              </div>
              <div
                style="
                  font-weight: bold;
                  font-size: 16px;
                  color: blue;
                  margin-top: 15px;
                "
              >
                {{ cardData.njlyl }} %
              </div>
              <div style="font-size: 10px; margin-top: 10px">
                <span v-if="false" style="font-size: 10px; color: green">
                  ↑ {{ 50 }}%</span
                >
                <span v-else style="font-size: 10px; color: red">
                  ↓ {{ cardData.njlyljzr }}%</span
                >
                <span style="font-size: 10px; color: gray"> 较昨日</span>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col :span="6">
            <el-row>
              <el-card style="width: 373px">
                <div>
                  <div style="font-size: 15px">农机品牌分布</div>
                </div>

                <div ref="chart" style="width: 373px; height: 200px">
                  <PieChart :chartData="BrandPieData"> </PieChart>
                </div>
              </el-card>
            </el-row>
            <el-row style="margin-top: 20px">
              <el-col :span="6">
                <el-card style="width: 373px">
                  <div>
                    <div style="font-size: 15px">区域作业面积</div>
                  </div>
                  <div ref="chart" style="width: 373px; height: 240px">
                    <BarChart
                      :chartData="BrandBarData"
                      :xAxisData="BrandxAxisData"
                      barColor="#91CC75"
                      :showTooltip="true"
                      :showLegend="true"
                    />
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="18">
            <el-card>
              <div>
                <div style="font-size: 15px">作业区域分布</div>
              </div>
              <!-- <div style="height: 623px"></div> -->
              <!-- <div class="map-container">
                <div ref="mapChart" style="width: 100%; height: 700px;">
                  <div v-if="loading" class="loading-spinner">
                  <div class="loader"></div>
                </div>
                </div>
              </div> -->
              <div class="bmapgl-echarts-container">
              <!-- 百度地图容器 -->
              <div ref="bmapContainer" class="map-box">
                <div v-if="loading" class="loading-spinner">
                  <div class="loader"></div>
                </div>
              </div>
            </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="4">
        <el-row>
          <el-col>
            <el-card>
              <div>
                <div style="font-size: 15px">24小时作业趋势</div>
              </div>
              <div ref="chart" style="width: 290px; height: 200px">
                <AreaChart
                  :chartData="TrendsAreaData"
                  :xAxisData="TrendsxAxisData"
                  areaColor="#91CC75"
                />
              </div>
            </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 20px">
          <el-col>
            <el-card>
              <div>
                <div style="font-size: 15px">合作社服务面积排行</div>
              </div>
              <div ref="chart" style="width: 373px; height: 200px">
                <RankChart
                  :rankData="serviceAreaData"
                  chartTitle="合作社服务面积排行"
                  barColor="#91CC75"
                />
              </div>
            </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 20px">
          <el-col>
            <el-card>
              <div>
                <div style="font-size: 15px">作业效率排行</div>
              </div>
              <div ref="chart" style="width: 373px; height: 200px">
                <RankChart
                  :rankData="workEfficiencyData"
                  unit="亩/小时"
                  chartTitle="作业效率排行"
                  barColor="#91CC75"
                />
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {
  selectCarBrandCountByAreaCode,
  selectHomeworkTrends,
  selectRegionalHomework,
  selectServiceAreaRanking,
  todayData,
  efficiencyRanking,
} from "@/api/sjfx";
import {
  machineTrajectoryData
} from "@/api/njjg";
import moment from "moment";
import BarChart from "./components/BarChart";
import AreaChart from "./components/AreaChart";
import PieChart from "./components/PieChart";
import RankChart from "./components/RankChart";
import * as echarts from "echarts";
export default {
  components: {
    PieChart,
    AreaChart,
    BarChart,
    RankChart,
  },
  data() {
    return {
      serviceAreaData: [],
      WorkEfficiencyData: [],
      BrandPieData: [],
      BrandxAxisData: [],
      BrandBarData: [],
      TrendsxAxisData: [],
      TrendsAreaData: [],
      cardData: {
        zxnjzs: "",
        zxnjzsjzr: 0,
        jrzymj: "",
        jrzymjjzr: 0,
        zywcl: "",
        zywcljzr: 0,
        njlyl: "",
        njlyljzr: 0,
      },
      trajectoryData: [], // 从API获取轨迹数据
      mapCenter: [116.404, 39.915], // 初始中心点
      mapZoom: 15,
      loading: false,
      map: null,            // 地图实例
      trajectories: [],     // 存储轨迹线对象
      vehicles: [],      // 车辆标记集合
      defaultConfig: {       // 默认配置
        center: [116.404, 39.915],  // 初始中心点
        zoom: 13,                   // 初始缩放级别
      },
      defaultStyle: {       // 默认轨迹样式
        strokeColor: "#3388ff",
        strokeWeight: 3,
        strokeOpacity: 0.7,
        enableClicking: true
      }
    }
   },
  mounted() {

    //隐藏头部和底部
    let header = document.querySelector(".header");
    let noShow = document.querySelector(".noShow");
    // let homeManage = document.querySelector(".home-manage");
    let footer = document.querySelector(".footer");
    header.style = "display:none";
    footer.style = "display:none";
    noShow.style = "display:none";
    // homeManage.style = "padding-top:0;width:100vw;";

    this.initBMapGL();
    this.$nextTick(() => {
      this.machineTrajectoryData();
    });
  },
  created() {
    //今日数据
    todayData().then((res) => {
      // console.log("todayData", res);
      this.cardData = {
        zxnjzs: res.data.zxnjzs.zxnjzs,
        zxnjzsjzr: res.data.zxnjzs.zxnjzsjzr,
        jrzymj: res.data.jrzymj.jrzymj,
        jrzymjjzr: res.data.jrzymj.jrzymjjzr,
        zywcl: res.data.zywcl.zywcl,
        zywcljzr: res.data.zywcl.zywcljzr,
        njlyl: res.data.njlyl.njlyl,
        njlyljzr: res.data.njlyl.njlyljzr,
      };
    });
    //作业效率排行
    efficiencyRanking().then((res) => {
      // console.log("efficiencyRanking", res);
      this.workEfficiencyData = res.data;
    });
    //合作社服务面积排行
    selectServiceAreaRanking().then((res) => {
      // console.log("合作社服务面积排行", res);
      this.serviceAreaData = res.data;
    });
    selectCarBrandCountByAreaCode().then((res) => {
      // console.log("selectCarBrandCountByAreaCode", res);
      this.BrandPieData = res.data;
    });
    selectHomeworkTrends().then((res) => {
      // console.log("selectHomeworkTrends", res);
      this.TrendsxAxisData = res.data.xAxisData;
      this.TrendsAreaData = res.data.areaData;
    });
    selectRegionalHomework().then((res) => {
      // console.log("selectRegionalHomework", res);
      this.BrandxAxisData = res.data.xAxisData;
      this.BrandBarData = res.data.areaData;
    });


  },
  methods: {
     // 初始化百度地图
     initBMapGL() {
      if (!window.BMapGL) {
        console.error('BMapGL未加载成功');
        return;
      }

      this.map = new BMapGL.Map(this.$refs.bmapContainer, {
        enableAutoResize: true
      });

      // 设置初始视图
      this.map.centerAndZoom(
        new BMapGL.Point(...this.defaultConfig.center),
        this.defaultConfig.zoom
      );
      this.map.enableScrollWheelZoom(true);
      this.map.enableDragging(true);
      this.map.setMapType(BMAP_SATELLITE_MAP);
      this.map.addControl(new BMapGL.ZoomControl());

      var bdary = new BMapGL.Boundary()
      let identityObj = JSON.parse(localStorage.getItem("identityObj"));
      let that = this;
      bdary.get('舒兰市', function (rs) {
          // 获取行政区域数量
          var count = rs.boundaries.length
          var pointArray = []
          for (var i = 0; i < count; i++) {
            var path = [];
          var xyArr = rs.boundaries[i].split(';');
          var ptArr = [];
          for (var j = 0; j < xyArr.length; j++) {
              var tmp = xyArr[j].split(',');
              var pt = new BMapGL.Point(tmp[0], tmp[1]);
              ptArr.push(pt);
          }
          // 添加掩膜
          var mapmask = new BMapGL.MapMask(ptArr, {
              isBuildingMask: true,
              isPoiMask: true,
              isMapMask: true,
              showRegion: 'inside',
          });
          that.map.addOverlay(mapmask);
        }
      })
    },

      /* 清空所有轨迹 */
    clearAllTrajectories() {
      this.trajectories.forEach(line => {
        this.map.removeOverlay(line);
      });
      this.trajectories = [];

      // 清除车辆标记
      this.vehicles.forEach(vehicle => {
        this.map.removeOverlay(vehicle);
      });
      this.vehicles = [];
    },

    /* 轨迹点击处理 */
    handlePolylineClick(event, polyline) {
      // 关闭之前的信息窗口
      if (this.currentInfoWindow) {
        this.map.closeInfoWindow(this.currentInfoWindow);
      }

      // 计算信息窗口位置（取轨迹中心点）
      const path = polyline.getPath();
      const centerPoint = this.calculateCenter(path);

      // 创建信息窗口内容
      const content = `
        <div class="info-window">
          <div>农机类型：${polyline.trajData.machineType}</div>
          <div>车牌号：${polyline.trajData.carNumber}</div>
          <div>驾驶员：${polyline.trajData.driverName}</div>
          <div>本次作业时长：${polyline.trajData.workTime}</div>
          <div>服务对象：${polyline.trajData.companyName}</div>
        </div>
      `;

      // 创建信息窗口
      this.currentInfoWindow = new BMapGL.InfoWindow(content, {
        width: 200,     // 信息窗口宽度
        height: 150,    // 信息窗口高度
        offset: new BMapGL.Size(0, -30) // 位置偏移
      });

      // 打开信息窗口
      this.map.openInfoWindow(this.currentInfoWindow, centerPoint);
    },
    /* 计算轨迹中心点 */
    calculateCenter(points) {
      const lngs = points.map(p => p.lng);
      const lats = points.map(p => p.lat);

      return new BMapGL.Point(
        (Math.max(...lngs) + Math.min(...lngs)) / 2,
        (Math.max(...lats) + Math.min(...lats)) / 2
      );
    },


     /* 绘制所有轨迹 */
     drawAllTrajectories() {
      this.clearAllTrajectories(); // 先清除现有轨迹

      this.trajectoryData.forEach(traj => {
        if (traj.points.length > 0) {
          traj.points.forEach(point => {
            if (point.coords.length > 0) {
              const points = point.coords.map(p => new BMapGL.Point(p[0], p[1]));
              // 创建折线对象
              const polyline = new BMapGL.Polyline(points, {
                strokeColor: '#00BFFF',
                strokeWeight: 1,
                strokeOpacity: 0.8,
                strokeStyle: 'solid'
              });

              // 添加自定义属性存储轨迹信息
              polyline.trajData = {
                machineType: traj.machineType,
                carNumber: traj.carNumber,
                driverName: traj.driverName,
                workTime: traj.workTime,
                companyName: traj.companyName
              };

              // 添加点击事件
              polyline.addEventListener('click', e => {
                this.handlePolylineClick(e, polyline);
              });

              // 添加到地图和集合
              this.map.addOverlay(polyline);
              this.trajectories.push(polyline);
            }
          });
        }
      });
      // this.drawMarker();
    },

    /* 绘制实时点 */
    drawMarker() {
      //获取最新的点
      let data = this.getLatestPoints(this.trajectoryData);
      var myIcon = new BMapGL.Icon("imgs/car.png", new BMapGL.Size(20, 20), {
        anchor: new BMapGL.Size(18, 18),  // 图标锚点
        imageSize: new BMapGL.Size(20, 20)
      });
      data.forEach((item) => {
        console.log('item',item);
        if (item != null) {
          // 创建标注对象并添加到地图
          var marker = new BMapGL.Marker(new BMapGL.Point(item.value[0], item.value[1]), {icon: myIcon});
          marker.vehicleData = item; // 绑定车辆数据

          // 添加点击事件
          marker.addEventListener('click', e => {
            this.showVehicleInfo(e.target);
          });
          this.map.addOverlay(marker);
          this.vehicles.push(marker);
        }
      });

    },
    /* 显示车辆信息 */
    showVehicleInfo(marker) {
      if (this.currentInfoWindow) {
        this.map.closeInfoWindow(this.currentInfoWindow);
      }

      const info = marker.vehicleData;
      const content = `
        <div class="vehicle-info">
          <div>农机类型：${info.machineType}</div>
          <div>车牌号：${info.carNumber}</div>
          <div>驾驶员：${info.driverName}</div>
          <div>本次作业时长：${info.workTime}</div>
          <div>服务对象：${info.companyName}</div>
        </div>
      `;

      this.currentInfoWindow = new BMapGL.InfoWindow(content, {
        width: 220,
        height: 160,
        offset: new BMapGL.Size(0, -40)
      });
      this.map.openInfoWindow(this.currentInfoWindow, marker.getPosition());
    },

    /* 清理资源 */
    cleanupMap() {
      this.clearAllTrajectories();
      if (this.map) {
        this.map.destroy();
        this.map = null;
      }
    },



    //轨迹数据
    async machineTrajectoryData() {
        this.loading = true;
        //获取当天开始时间和结束时间
        const dayDate = new Date();
        const startTime = moment(dayDate).format("YYYY-MM-DD 00:00:00")
        const endTime = moment(dayDate).format("YYYY-MM-DD 23:59:59")
        let params = {
          year: "",
          orderContent: "",
          cropCode: "",
          companyName: "",
          startTime: startTime,
          endTime: endTime,
        }
        let res = await machineTrajectoryData(params)
        if (res.code == 0) {
          this.trajectoryData = res.data;
        } else {
          this.$message({
            type: 'info', // success error warning
            message: res.msg,
            duration: 2000,
          })
        }
        this.autoCenter();
    },
  // 数据清洗和转换
  processTrackData(rawData) {
          // 顶层判空
      if (!Array.isArray(rawData)) return [];

      return rawData.flatMap((track, index) => {
        // 轨迹对象有效性检查
        if (typeof track !== 'object' || track === null) return [];

        // 必需字段默认值处理
        const safeTrack = {
          machineType: '',
          carNumber: '',
          driverName: '',
          workTime: 0,
          workArea: '',
          companyName: '',
          points: [],
          ...track
        };

        // 点数据有效性检查
        if (!Array.isArray(safeTrack.points)) return [];

        return safeTrack.points.flatMap(point => {
          // 点对象基础检查
          if (typeof point !== 'object' || point === null) return [];

          // 坐标数据检查 + 默认值
          const coords = Array.isArray(point.coords) ? point.coords : [];

          return [{
            // 元数据带默认值
            machineType: safeTrack.machineType,
            carNumber: safeTrack.carNumber,
            driverName: safeTrack.driverName,
            workTime: Number.isFinite(safeTrack.workTime)
              ? safeTrack.workTime
              : 0,
            workArea: safeTrack.workArea,
            companyName: safeTrack.companyName,
            // 处理后的坐标
            coords: coords,
            // 样式对象带默认值
            lineStyle: {
              color: "#00BFFF",
              width: 1,
              opacity: Math.min(1, Math.max(0,
                typeof safeTrack.opacity === 'number'
                  ? safeTrack.opacity
                  : 0.7
              )),
              curveness: 0,
            }
          }];
        });
      });
    },

    // 去除连续重复点
    removeDuplicatePoints(points) {
      return points.filter((point, index) => {
        if (index === 0) return true
        const prev = points[index - 1]
        return !(point[0] === prev[0] && point[1] === prev[1])
      })
    },
    // 计算轨迹边界中心点
    calculateTrackCenter(tracks) {
      let minLng = Infinity,
          maxLng = -Infinity,
          minLat = Infinity,
          maxLat = -Infinity

      // 遍历所有轨迹点
      tracks.forEach((track) => {
        track.points.forEach((point) => {
          point.coords.forEach((coord) => {
            const [lng, lat] = coord;
            minLng = Math.min(minLng, lng);
            maxLng = Math.max(maxLng, lng);
            minLat = Math.min(minLat, lat);
            maxLat = Math.max(maxLat, lat);
          });

        });
      });

      // 计算中心点
      const centerLng = (minLng + maxLng) / 2
      const centerLat = (minLat + maxLat) / 2

      // 计算推荐缩放级别（经验公式）
      const lngDiff = maxLng - minLng
      const latDiff = maxLat - minLat
      const zoom = Math.floor(
        9 - Math.log(Math.max(lngDiff, latDiff * 1.5)) / Math.LN2
      )

      return {
        center: [centerLng, centerLat],
        zoom: Math.min(17, Math.max(14, zoom))
      }
    },

     // 自动居中方法
     autoCenter() {
      var flag = false;
      if (this.trajectoryData.length > 0) {
        this.trajectoryData.forEach((item) => {
          if (item.points.length > 0) {
            flag = true;
          }
        });
      }
      if (flag) {
        const { center, zoom } = this.calculateTrackCenter(this.trajectoryData);
        this.defaultConfig.center = center;
        this.defaultConfig.zoom = 12;
        // 更新地图视图
        this.initBMapGL();
        this.drawAllTrajectories();
      } else {
        this.clearAllTrajectories();
        var myGeo = new BMapGL.Geocoder();
        // 将地址解析结果显示在地图上，并调整地图视野
        let identityObj = JSON.parse(localStorage.getItem("identityObj"));
        const self = this;
        // myGeo.getPoint(identityObj.content.areaFullName, (point) => {
          myGeo.getPoint('舒兰市', (point) => {
            if (point) {
                self.defaultConfig.center = [point.lng, point.lat];
                self.defaultConfig.zoom = 12;
                this.initBMapGL();
            } else {
                console.error('未找到该地点的坐标');
                return null;
            }
        });
      }

    },
    // initMap() {
    //   const chartDom = this.$refs.mapChart
    //   const myChart = echarts.init(chartDom)

    //   myChart.setOption({
    //     bmap: {
    //       center: this.mapCenter, // 初始中心点
    //       zoom: this.mapZoom,
    //       roam: true, // 允许缩放和拖拽
    //       mapStyle: {
    //         styleJson: [{
    //           "featureType": "highway",
    //           "elementType": "all",
    //           "stylers": { "visibility": "off" }
    //         }]
    //       }
    //     },
    //     series: [{
    //       type: 'lines',
    //       coordinateSystem: 'bmap',
    //       data: this.processTrackData(this.trajectoryData),
    //       polyline: true,
    //       large: true,  // 启用大数据模式
    //       largeThreshold: 2000, // 数据量>2000时启用优化
    //       progressiveChunkMode: 'mod', // 分片渲染
    //       blurSize: 2,      // GPU加速模糊
    //       layerId: 'track',  // 独立渲染层
    //       lineStyle: {
    //         color: '#55a8ff',
    //         width: 1,
    //         opacity: 0.5
    //       },
    //       effect: {
    //         show: false,
    //       }
    //     }],
    //     tooltip: {
    //       trigger: 'item',
    //       formatter: params => {
    //         const points = params.data.coords
    //         return `
    //           <div style="padding:5px">
    //             <div>农机类型：${params.data.machineType}</div>
    //             <div>车牌号：${params.data.carNumber}</div>
    //             <div>驾驶员：${params.data.driverName}</div>
    //             <div>本次作业时长：${params.data.workTime}</div>
    //             <div>服务对象：${params.data.companyName}</div>
    //           </div>
    //         `
    //       }
    //     }
    //   });

    //   // 关联百度地图实例
    //   const bmap = myChart.getModel().getComponent('bmap').getBMap();
    //   bmap.setMapType(BMAP_HYBRID_MAP);
    //   bmap.setMaxZoom(17.4);

    //   // 添加地图控件
    //   bmap.addControl(new BMap.NavigationControl());
    // }
  }
};
</script>
<style lang="less" scoped>
.loading-spinner {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* 背景半透明 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.loader {
  border: 4px solid rgba(0, 0, 0, 0.1); /* 边框颜色 */
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f; /* 转圈颜色 */
  animation: spin 1s infinite linear;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bmapgl-echarts-container {
  position: relative;
  width: 100%;
  height: 700px;
}

.map-box {
  width: 100%;
  height: 100%;
}

.echarts-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* 允许穿透操作地图 */
}
</style>
