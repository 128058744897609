<template>
  <div ref="chart" style="width: 285px; height: 230px"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "RankChart",
  props: {
    rankData: {
      type: Array,
      required: true,
      validator: (value) => {
        return value.every((item) => item.name && item.value);
      },
    },
    unit: {
      type: String,
      default: "亩",
    },
    barColor: {
      type: String,
      default: "#5470C6",
    },
  },
  data() {
    return {
      chartInstance: null,
    };
  },
  mounted() {
    this.initChart();
  },
  watch: {
    rankData: {
      handler() {
        this.updateChart();
      },
      deep: true,
    },
  },
  methods: {
    initChart() {
      this.chartInstance = echarts.init(this.$refs.chart);
      this.updateChart();
    },
    updateChart() {
      // 对数据进行排序（从大到小）
      const sortedData = [...this.rankData].sort((a, b) => b.value - a.value);

      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          data: sortedData.map((item) => item.name),
          axisLabel: {
            fontSize: 14,
          },
          inverse: true, // 让排名从上到下显示

        },
        yAxis: {
          type: "value",
          // show: false, // 隐藏 X 轴
        },
        grid: {
          left: "0%",
          right: "5%",
          bottom: "5%",
          top: "5%",
          containLabel: true,
        },
        series: [
          {
            name: this.unit,
            type: "bar",
            data: sortedData.map((item) => item.value),
            itemStyle: {
              color: this.barColor,
            },
            label: {
              show: true,
              position: "right",
              formatter: "{c}", // 显示数值
            },
          },
        ],
      };

      this.chartInstance.setOption(option);
    },
  },
  beforeUnmount() {
    if (this.chartInstance) {
      this.chartInstance.dispose();
    }
  },
};
</script>

<style scoped>
/* 你可以在这里添加一些样式 */
</style>
