<template>
  <div ref="chart" style="width: 100%; height: 200px"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "BarChart",
  props: {
    chartData: {
      type: Array,
      required: true,
    },
    xAxisData: {
      type: Array,
      required: true,
    },

    barColor: {
      type: String,
      default: "#5470C6",
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
    showLegend: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chartInstance: null,
    };
  },
  mounted() {
    this.initChart();
  },
  watch: {
    chartData: {
      handler() {
        this.updateChart();
      },
      deep: true,
    },
    xAxisData: {
      handler() {
        this.updateChart();
      },
      deep: true,
    },
  },
  methods: {
    initChart() {
      this.chartInstance = echarts.init(this.$refs.chart);
      this.updateChart();
    },
    updateChart() {
      const option = {
        tooltip: {
          show: this.showTooltip,
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "2%",
          right: "10%",
          bottom: "5%",
          top: "20%",
          containLabel: true,
        },
        legend: {
          show: this.showLegend,
          data: ["数据"],
        },
        xAxis: {
          type: "category",
          data: this.xAxisData,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "数据",
            type: "bar",
            data: this.chartData,
            itemStyle: {
              color: this.barColor,
            },
          },
        ],
      };

      this.chartInstance.setOption(option);
    },
  },
  beforeUnmount() {
    if (this.chartInstance) {
      this.chartInstance.dispose();
    }
  },
};
</script>

<style scoped>
/* 你可以在这里添加一些样式 */
</style>
