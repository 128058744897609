import service from "../request";
const baseUrl = window.globalUrl.HOME_API;

//区域作业面积
export const todayData = (params) => {
  return service({
    url: `${baseUrl}/trade-website/njzysjfx/todayData`,
    method: "get",
  });
};

//查询农机品牌分布
export const selectCarBrandCountByAreaCode = (params) => {
  return service({
    url: `${baseUrl}/trade-website/njzysjfx/selectCarBrandCountByAreaCode`,
    method: "get",
  });
};

//24小时作业趋势
export const selectHomeworkTrends = (params) => {
  return service({
    url: `${baseUrl}/trade-website/njzysjfx/selectHomeworkTrends`,
    method: "get",
  });
};

//区域作业面积
export const selectRegionalHomework = (params) => {
  return service({
    url: `${baseUrl}/trade-website/njzysjfx/selectRegionalHomework`,
    method: "get",
  });
};

//区域作业面积
export const selectServiceAreaRanking = (params) => {
  return service({
    url: `${baseUrl}/trade-website/njzysjfx/selectServiceAreaRanking`,
    method: "get",
  });
};

//查询作业环节
export const efficiencyRanking = (params) => {
  return service({
    url: `${baseUrl}/trade-website/njzysjfx/efficiencyRanking`,
    method: "get",
    params,
  });
};
